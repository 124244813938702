import { richTextResolver } from "@storyblok/richtext";

export const useRichText = (doc: any) => {
  if (doc?.content.length < 2 && (!doc?.content[0]?.content || doc?.content[0]?.content?.length === 0)) {
    return "";
  }
  
  return richTextResolver({
    optimizeImages: true,
  }).render(doc);
};
