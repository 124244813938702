declare global {
  interface Window {
    dataLayer: any[];
  }
}

export default defineNuxtPlugin({
  hooks: {
    "page:finish"() {
      const route = useRoute();
      if (route.matched.length > 0) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: "Page found",
        });
      }
    },
  },
});
